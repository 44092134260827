<template>
  <transition name="fade" mode="out-in">
    <div v-if="hasFiilters" class="filter-list px-4 py-2 d-flex align-items-center mb-3 rounded">
      <span class="text-dark-darken mr-4">
        <small
          ><b>{{ $t('general.filterListLabel') }}</b>
        </small></span
      >

      <uello-button
        class="filter-list-item"
        color="black"
        v-for="(filter, index) in filters"
        :key="`filter-item-${index}`"
      >
        <small>
          <span class="text-white" v-if="filter.state"
            ><b>{{ filter.label }}:</b> {{ filter.text }}</span
          >
          <span class="text-white" v-else
            ><b>{{ filter.label }}:</b> {{ filter.value | valueFormat }}</span
          >
        </small>
        <close-button :handleClick="() => removeFilter(filter.field)" :size="20" class="ml-2" />
      </uello-button>
      <uello-button
        color="red"
        @click="clearFilters"
        icon="delete"
        :text="$t('general.resetFilter')"
      ></uello-button>
    </div>
  </transition>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { UelloButton } from '@uello/componentello';

export default {
  name: 'filter-list',
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    UelloButton,
  },
  filters: {
    valueFormat(value) {
      if (value.value.length > 40) {
        return `${value.value.slice(0, -(value.value.length - 40))}...`;
      }
      if (value.from && value.to) {
        let string = format(parseISO(value.from), 'dd/MM/yyyy');
        string += value.to !== value.from ? format(parseISO(value.to), "' à' dd/MM/yyyy") : '';
        return string;
      }
      if (value.value) {
        return value.value;
      }
      return value;
    },
  },
  computed: {
    hasFiilters() {
      return this.filters.length > 0;
    },
  },
  methods: {
    clearFilters() {
      this.$emit('resetFilters');
    },
    removeFilter(field) {
      this.$emit('remove', field);
    },
  },
};
</script>

<style lang="scss">
.filter-list {
  background-color: rgba(0, 0, 0, 0.15);

  .filter-list-item {
    width: fit-content;
    cursor: initial;

    & + button {
      margin-left: 1rem;
    }
  }
}
</style>
